<template>
  <div class="users py-5">
    <v-row class="w-100">
      <v-col cols="12" :md="this.paramID ? 6 : 12">
        <div class="list-header">
          <div
            class="list-head d-flex justify-space-between align-center pt-5"
          >
            <span>
              <p class="gray--text mt-1">Users ({{ total }})</p>
            </span>
            <base-input
              placeholder="Search User"
              @input="searchUser"
            ></base-input>
          </div>
        </div>
        <div class="mb-1">
          <!-- table -->
          <v-data-table
            :headers="headers"
            :items="[{}, {}, {}, {}]"
            item-key="_id"
            class="users-table elevation-0 mt-4"
            v-if="loading"
          >
            <template v-slot:item>
              <tr>
                <td v-for="(item, i) in [1, 2, 3, 4]" :key="i">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div v-if="!loading">
            <v-data-table
              :headers="headers"
              :items="users"
              item-key="_id"
              :item-class="
                (item) => {
                  return paramID === item._id
                    ? 'gray lighten-4 cursor-pointer'
                    : 'cursor-pointer';
                }
              "
              class="users-table"
              height="calc(100vh - 150px)"
              :page.sync="page"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :items-per-page.sync="itemsPerPage"
              :footer-props="{ 'items-per-page-options': [5, 15, 30, 50] }"
              :server-items-length="total"
              mobile-breakpoint="0"
              @click:row="viewItem"
              @update:page="fetchList"
              @update:items-per-page="fetchList"
              @update:sort-by="fetchList"
              @update:sort-desc="fetchList"
              fixed-header
            >
              <!-- name & email -->
              <template v-slot:item.name="{ item }">
                <span class="d-block font-13 white-grey--text">{{
                  item.name
                }}</span>
                <span class="d-block font-12 grey--text">{{ item.email }}</span>
              </template>

              <!-- avatar -->
              <template v-slot:item.avatar="{ item }">
                <div class="my-2">
                  <img
                    v-if="item.google"
                    class="user-avatar"
                    :src="item.google.picture"
                    alt=""
                  />
                  <img v-else class="user-avatar" :src="avatar" alt="" />
                </div>
              </template>

              <!-- last active -->
              <template v-slot:item.lastActive="{ item }">
                <span
                  v-if="item.lastActive"
                  class="d-block font-13 white-grey--text"
                  >{{ $moment(item.lastActive).format("LLLL") }}</span
                >
              </template>

              <!-- last login -->
              <template v-slot:item.lastLogin="{ item }">
                <span
                  v-if="item.lastLogin"
                  class="d-block font-13 white-grey--text"
                  >{{ $moment(item.lastLogin).format("LLLL") }}</span
                >
              </template>

              <!-- create time -->
              <template v-slot:item.created_at="{ item }">
                <span
                  v-if="item.created_at"
                  class="d-block font-13 white-grey--text"
                  >{{ $moment(item.created_at).format("LLLL") }}</span
                >
              </template>
            </v-data-table>
          </div>
        </div>
      </v-col>
      <!-- User Details -->
      <v-col cols="12" class="main-item" :md="6" v-if="this.paramID">
        <user-details :item="item" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
const SortEnum = {
  NAMEASC: 1,
  NAMEDESC: 2,
  LASTACTIVEASC: 3,
  LASTACTIVEDESC: 4,
  CREATEDATASC: 5,
  CREATEDATDESC: 6,

  init: function (sortBy, sortDesc) {
    const descSuffix = sortDesc ? "DESC" : "ASC";
    return this[
      `${sortBy
        .replaceAll(" ", "")
        .replaceAll(".", "")
        .replaceAll("_", "")
        .toUpperCase()}${descSuffix}`
    ];
  }
};
import UserDetails from "@/components/User/UserDetails.vue";
export default {
  components: {
    UserDetails
  },
  data: () => ({
    avatar: require("@/assets/images/global/avatar.png"),
    paramID: "",
    headers: [
      {
        text: "Avatar",
        align: "start",
        sortable: false,
        value: "avatar",
        width: "5%"
      },
      { text: "Name", value: "name", width: "35%" },
      {
        text: "Last active",
        value: "lastActive",
        width: "25%"
      },
      {
        text: "Last login",
        value: "lastLogin",
        width: "25%"
      },
      {
        text: "registered since",
        value: "created_at",
        width: "25%"
      }
    ],
    users: [],
    page: 1,
    total: 0,
    source: undefined,
    loading: true,
    sortBy: "created_at",
    sortDesc: true,
    itemsPerPage: 15,
    searchWord: undefined,
    item: undefined
  }),
  watch: {
    "$route.params": {
      async handler() {
        this.paramID = this.$route.params.id;
        await this.fetchItemByID();
      }
    }
  },
  methods: {
    async searchUser(searchWord) {
      this.users = [];
      this.page = 1;
      this.searchWord = searchWord;
      await this.fetchList();
      if (!this.$vuetify.breakpoint.mobile) {
        if (!searchWord || searchWord.length == 0) {
          this.paramID = this.$route.params.id;
          this.fetchItemByID();
        } else if (this.users.length > 0) {
          this.paramID = this.users[0]._id;
          this.fetchItemByID();
        } else {
          this.item = undefined;
        }
      }
    },
    viewItem(item) {
      this.$router.push("/users/" + item._id);
    },
    async fetchList() {
      this.loading = true;
      if (this.source) {
        this.source.cancel();
      }
      this.source = this.$axios.CancelToken.source();
      const currentSource = this.source;
      const search =
        this.searchWord && this.searchWord.length > 0
          ? `&searchWord=${this.searchWord}`
          : "";
      const sort = this.sortBy
        ? `&sort=${SortEnum.init(this.sortBy, this.sortDesc)}`
        : "";
      var pageSize = this.itemsPerPage;
      if (this.itemsPerPage == -1) {
        pageSize = "";
      }
      let { data } = await this.$axios.get(
        `users/all/new?pageSize=${pageSize}&pageNumber=${this.page}${sort}${search}`
      );
      if (data) {
        this.users = data.data;
        this.total = data.count;
      }
      if (currentSource === this.source) {
        this.loading = false;
      }
    },
    async fetchItemByID() {
      if (this.paramID) {
        let { data } = await this.$axios.get(`/users/${this.paramID}`);

        if (data) {
          this.item = data.data;
        }
      }
    }
  },

  async created() {
    this.paramID = this.$route.params.id;
    await this.fetchList();
    await this.fetchItemByID();
  }
};
</script>

<style lang="scss">
.users {
  overflow: hidden;
  height: 100vh;
  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  .main-item {
    height: 100vh;
    overflow: hidden;
  }
  .user-details-details {
    overflow: auto;
    height: calc(100vh - 230px);
  }
}
</style>
